































































































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import NetworkRow from "./NetworkRow.vue";
import CustomPage from "./CustomPage.vue";
import ListPage from "./ListPage.vue";
import EditPage from "@/components/NetworkSettings/EditPage.vue";
import { AvaNetwork } from "@/js/AvaNetwork";
import { NetworkStatus } from "@/store/modules/network/types";

@Component({
  components: {
    ListPage,
    NetworkRow,
    CustomPage,
    EditPage,
  },
})
export default class NetworkMenu extends Vue {
  page: string = "list";
  isActive: boolean = false;
  editNetwork: AvaNetwork | null = null;

  viewCustom(): void {
    this.page = "custom";
  }
  viewList(): void {
    this.page = "list";
  }
  closeMenu(): void {
    this.page = "list";
    this.isActive = false;
  }
  toggleMenu(): void {
    this.isActive = !this.isActive;
  }
  addCustomNetwork(data: AvaNetwork): void {
    this.$store.dispatch("Network/addCustomNetwork", data);
    this.page = "list";
  }

  get connectionColor(): string {
    switch (this.status) {
      case "connecting":
        return "#ffaa00";
      case "connected":
        return "#0f0";
      default:
        return "#f00";
    }
  }

  networkUpdated() {
    this.page = "list";
    this.$store.dispatch("Network/save");
  }

  onedit(network: AvaNetwork): void {
    this.editNetwork = network;
    this.page = "edit";
  }

  get status(): NetworkStatus {
    return this.$store.state.Network.status;
  }
  get activeNetwork(): null | AvaNetwork {
    return this.$store.state.Network.selectedNetwork;
  }
  get networks(): AvaNetwork[] {
    return this.$store.getters("Network/allNetworks");
    // return this.$store.state.Network.networks;
  }

  get isTestnet(): boolean {
    let net = this.activeNetwork;

    if (!net) return false;
    if (net.networkId !== 1) return true;
    return false;
  }
}
