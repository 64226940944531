<template>
  <v-app>
    <v-main>
      <template>
        <navbar v-show="isNavbar"></navbar>
        <div class="main_cols" :wallet_view="!isNavbar">
          <TestNetBanner></TestNetBanner>
          <UpgradeToAccountModal></UpgradeToAccountModal>
          <transition name="fade" mode="out-in">
            <router-view id="router_view" />
          </transition>
        </div>
      </template>
    </v-main>
    <LedgerBlock ref="ledger_block"></LedgerBlock>
    <LedgerUpgrade></LedgerUpgrade>
    <LedgerWalletLoading></LedgerWalletLoading>
    <NetworkLoadingBlock></NetworkLoadingBlock>
    <notifications></notifications>
    <analytics></analytics>
  </v-app>
</template>
<script>
import Notifications from "@/components/Notifications";
import TestNetBanner from "@/components/TestNetBanner";
import Analytics from "@/components/Analytics/Analytics";
import Navbar from "./components/Navbar";
import SaveAccountModal from "@/components/modals/SaveAccount/SaveAccountModal";
import LedgerBlock from "@/components/modals/LedgerBlock";
import LedgerUpgrade from "@/components/modals/LedgerUpgrade";
import NetworkLoadingBlock from "@/components/misc/NetworkLoadingBlock";
import UpgradeToAccountModal from "@/components/modals/SaveAccount/UpgradeToAccountModal";
import LedgerWalletLoading from "@/components/modals/LedgerWalletLoading";

export default {
  components: {
    LedgerWalletLoading,
    UpgradeToAccountModal,
    TestNetBanner,
    NetworkLoadingBlock,
    LedgerBlock,
    LedgerUpgrade,
    Analytics,
    SaveAccountModal,
    Navbar,
    Notifications,
  },
  async created() {
    // Init language preference
    let locale = localStorage.getItem("lang");
    if (locale) {
      this.$root.$i18n.locale = locale;
    }

    await this.$store.dispatch("Network/init");
    this.$store.commit("Accounts/loadAccounts");
    this.$store.dispatch("Assets/initErc20List");
    this.$store.dispatch("Assets/ERC721/init");
    this.$store.dispatch("updateDjtxPrice");

    if (this.$store.state.Accounts.accounts.length > 0) {
      // Do not route for legal pages
      if (this.$route.name !== "legal") {
        this.$router.push("/access");
      }
    }
  },
  computed: {
    isNavbar() {
      if (this.$route.path.includes("/wallet")) {
        return false;
      }
      return true;
    },
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Dijets wallet is a simple, highly secure, non-custodial crypto wallet for storing DJT.",
      },
      {
        vmid: "og:description",
        name: "description",
        content:
          "Dijets wallet is a simple, highly secure, non-custodial crypto wallet for storing DJT.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Fastest Performing and Secure DeFi Wallet | Dijets Wallet",
      },
    ],
    title: "Dijetal Asset Management",
    titleTemplate: "%s | Dijets Wallet",
  },
};
</script>

<style scoped lang="scss">
@use "./main";

@import url("https://fonts.cdnfonts.com/css/uber-move-text");

@font-face {
  font-family: "FeyCalibre-Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/FeyCalibre-light.woff") format("woff");
}
@font-face {
  font-family: "FeyCalibre-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/FeyCalibre-Regular.woff") format("woff");
}
@font-face {
  font-family: "FeyCalibrBold";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/FeyCalibre-bold.woff") format("woff");
}
@font-face {
  font-family: "FeyCalibre";
  font-style: normal;
  src: url("https://www.feyapp.com/_next/static/media/69c8001088dcc787-s.p.woff2")
    format("woff2");
}

.main_cols {
  &[wallet_view] {
    height: 100vh;

    #router_view {
      overflow: auto;
      padding: 0;
      padding-bottom: 0px;
    }
  }

  #router_view {
    min-height: calc(100vh - 80px);
    position: relative;
    padding: main.$container_padding_m;
  }
}

#router_view {
  min-height: calc(100vh - 80px);
  position: relative;
  padding: main.$container_padding_m;
  overflow: auto;
}

/*.panel {*/
/*    background-color: #fff;*/
/*    overflow: auto;*/
/*    height: 100%;*/
/*}*/
</style>

<style lang="scss">
@use "./main";

html {
  height: 100%;
}

body {
  height: 100%;
}

p {
  margin: 0px !important;
}

#app {
  min-height: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  text-size-adjust: none;
  text-align: left;
  color: var(--primary-color);
  background: rgb(11, 11, 15) !important;
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  font-family: "FeyCalibre-Regular", sans-serif;
  transition-duration: 0.2s;
}

#nav {
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: transparent;
  padding: main.$container_padding_m;
}

.checkbox {
  margin: 4px 0px 10px !important;
}

.v-application a {
  text-decoration: none !important;
}

.v-stepper__label {
  margin-left: 10px;
}

.v-label {
  font-size: 14px !important;
}

.v-stepper__header {
  z-index: 1;
}

.steper__body {
  margin-top: 10px !important;
  overflow: auto !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(255 255 255) !important;
}

input.single_line_input.hover_border {
  position: relative !important;
  height: 44px !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.06) !important;
  border: 0.5px solid rgba(255, 255, 255, 0.08) !important;
  box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 1px inset !important;
  backdrop-filter: blur(20px) !important;
  border-radius: 7px !important;
  line-height: 19px !important;
  padding: 10px 10px 10px 21px !important;
  display: flex !important;
  cursor: text !important;
  margin-bottom: 18px !important;
}

::placeholder {
  color: rgba(255, 255, 255, 0.281) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.281);
}

::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.281);
}

.single_line_input {
  height: 44px !important;
  border: 0.5px solid rgba(255, 255, 255, 0.08) !important;
  box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 1px inset !important;
  border-radius: 99px !important;
  line-height: 19px !important;
  padding: 10px 10px 10px 21px !important;
  display: block !important;
  cursor: text !important;
  margin-bottom: 18px !important;
}

.v-tooltip__content {
  background: rgba(0, 0, 0, 1) !important;
  border: 2px solid rgba(0, 0, 0, 1);
  color: #fff !important;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.88rem !important;
  line-height: 1.57em !important;
  padding: 8px 16px;

  .light {
    font-weight: 300;
  }
}

.export_wallet .formIn .v-input__slot {
  background-color: #0b0b0c !important;
}

.v-text-field:hover {
  border-color: #6a91d8 !important;
}

::placeholder {
  color: rgb(179, 179, 179) !important;
}

@include main.mobile-device {
  #router_view {
    padding: 16px !important;
  }

  #nav {
    padding: main.$container_padding_mobile;
    display: flex !important;
  }

  body {
    padding-left: 0px !important;
  }
  aside.mobile_menu.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--is-mobile.v-navigation-drawer--is-mouseover.v-navigation-drawer--open.theme--light {
    z-index: 3 !important;
  }

  /*.main_cols {*/
  /*    grid-template-columns: 1fr !important;*/
  /*    &[wallet_view] {*/
  /*        height: auto !important;*/
  /*    }*/
  /*}*/
  .panel {
    display: none !important;
  }
}

@include main.medium-device {
  .main_cols {
    &[wallet_view] {
      grid-template-columns: 180px 1fr 240px !important;
    }
  }
}

@media only screen and (max-width: main.$width_s) {
  #router_view {
    padding: main.$container_padding_s;
  }
  #nav {
    padding: main.$container_padding_s;
  }
}
</style>
